// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-buscar-js": () => import("./../../../src/pages/buscar.js" /* webpackChunkName: "component---src-pages-buscar-js" */),
  "component---src-pages-etiquetas-js": () => import("./../../../src/pages/etiquetas.js" /* webpackChunkName: "component---src-pages-etiquetas-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jueves-cultural-js": () => import("./../../../src/pages/jueves-cultural.js" /* webpackChunkName: "component---src-pages-jueves-cultural-js" */),
  "component---src-templates-brochure-template-js": () => import("./../../../src/templates/brochureTemplate.js" /* webpackChunkName: "component---src-templates-brochure-template-js" */),
  "component---src-templates-interna-evento-template-js": () => import("./../../../src/templates/internaEventoTemplate.js" /* webpackChunkName: "component---src-templates-interna-evento-template-js" */),
  "component---src-templates-lastupdate-js": () => import("./../../../src/templates/lastupdate.js" /* webpackChunkName: "component---src-templates-lastupdate-js" */)
}

